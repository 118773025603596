export const utilityMappings = [
  { keywords: [/fogsurcharge|^sw\s|sewer|sewage|wastewater|w\/w|(?<!RPZ)backflow|grease|GRP/i], utilityType: "Sewer" },
  { keywords: [/StormWater|surfacewater|drainage|storm|cleanriversiac|riversmart|Parcel/i], utilityType: "Storm" },
  { keywords: [/electric/i], utilityType: "Electric" },
  { keywords: [/Gas/], utilityType: "Gas" },
  { keywords: [/lighting/i], utilityType: ["Lighting", "Electric"] },
  { keywords: [/sprinkler|irriga|sprinkl/i], utilityType: "Irrigation" },
  { keywords: [/fireservice|fireline/i], utilityType: "Fireline" },
  { keywords: [/water|potable/i, /^[1-2]$/], utilityType: ["Potable", "Irrigation"] },
  { keywords: [/recycling|recy/i], utilityType: "Recycling" },
  { keywords: [/waste|garbage|dumpster|landfill|sharedcontainer/i], utilityType: "Waste" },
];

const meterNumberMatches = (li, uam, textractBill) => {
  if(!li.meter_num) return false
  return [uam.meter_num, uam.um_meter_num].includes(li.meter_num) && uam.utility_company_id == textractBill.utility_company_id
}

export function findMatchingUtility(li, uams, textractBill) {
  let chosenUam;
  if (!li.description) return uams[0]
  for (const mapping of utilityMappings) {
    const { keywords, utilityType } = mapping;
    if (keywords.some(keyword => keyword.test(li.description.replace(/\s/g, "")))) {
      let chosenUam;

      const utilityTypes = Array.isArray(utilityType) ? utilityType : [utilityType];

      for (const type of utilityTypes) {
        chosenUam = uams.find(uam => uam.display_name.includes(type) && meterNumberMatches(li, uam, textractBill));
        if (chosenUam) return chosenUam;

        chosenUam = uams.find(uam => uam.display_name.includes(type));
        if (chosenUam) return chosenUam;
      }
    }
  }
  chosenUam = uams.find(uam => meterNumberMatches(li, uam, textractBill))
  chosenUam = chosenUam || uams.find(uam => uam.utility_company_id == textractBill.utility_company_id)
  chosenUam = chosenUam || uams[0]
  return chosenUam;
}
